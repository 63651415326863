* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

img {
   max-width: 100%;
}

a {
   color: inherit;
   text-decoration: none;
}

html {
   height: 100%;
   padding-top: 100px;
}

body {
   height: 100%;
   overflow-y: scroll; 
}

body::-webkit-scrollbar {
   display: none;
   width: 0;  
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
}

/* If the above doesn't work, try adding this catch-all rule */
::-webkit-scrollbar {
   display: none;
   width: 0;
}